.overview {
    .inner-overview-container {
        .card {
            border-radius: 1rem;
            .card-body {
                .row {
                    margin-bottom: 10px;
                    .btn-apply {
                        .btn {
                            margin-left: 12px;
                        }
                    }
                    button {
                        &.dropdown-toggle {
                            width: 100%;
                            text-align: left;
                            &::after {
                                float: right;
                            }
                        }
                    }
                    .dropdown-menu {
                        width: 375px;
                        top: -12px !important;
                        border-radius: 0;
                        a {
                            font-size: 13px;
                            color: #333;
                            &.dropdown-item.active {
                                color: #007bff;
                            }
                        }
                    }
                }
                .no-data {
                    text-align: center;
                    display: block;
                }
                .tag-label {
                    margin-top: 8px;
                    margin-left: 12px;
                    .tag-ui {
                        border: 1px solid #ddd;
                        padding-right: 5px;
                        margin-right: 10px;
                        margin-bottom: 5px;
                        float: left;
                        height: 28px;
                        border-radius: 20px;
                        span {
                            &.tag-type {
                                background: #429a86;
                                border-radius: 10px 0px 10px 0px;
                                height: 27px;
                                display: inline-block;
                                padding: 4px 6px;
                                text-transform: capitalize;
                                color: #fff;
                                border-right: 1px solid #ddd;
                                margin-right: 5px;
                                font-weight: 500;
                                font-size: 14px;
                                position: relative;
                                top: 0px;
                            }
                            &.tag-name {
                                padding-right: 5px;
                                font-size: 14px;
                            }
                            &.tag-cross {
                                color: #ff6258;
                                cursor: pointer;
                                font-weight: bold;
                                &.disabled-click {
                                    pointer-events: none;
                                    cursor: not-allowed;
                                    opacity: 0.4;
                                }
                            }
                        }
                    }
                }
                .table-brb {
                    margin-top: 15px;
                    .table-responsive {
                        overflow: hidden;
                        table {
                            &.min-hght {
                                min-height: 200px;
                            }
                            thead {
                                background: #404f5a;
                                color: #fff;
                                th i {
                                    float: right;
                                }
                            }
                            tbody {
                                .spinner-table {
                                    &.top-0 {
                                        .spinner-wrapper {
                                            top: -15px;
                                            width: calc(100% - 200px);
                                        }
                                    }
                                    .spinner-wrapper {
                                        top: 50px;
                                        width: calc(100% - 200px);
                                    }
                                }
                                tr {
                                    td {
                                        padding: 15px;
                                        &.active {
                                            color: #429a86;
                                            cursor: pointer;
                                            font-weight: 500;
                                        }
                                        &.clr-grn {
                                            color: #92d050;
                                            font-weight: 500;
                                        }
                                        &.clr-rd {
                                            color: #ff6258;
                                            font-weight: 500;
                                        }
                                        div {
                                            height: 20px;
                                            width: 90px;
                                            border-radius: 5px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-weight: 500;
                                            margin: auto;
                                            &.product-state-active {
                                                color: #f7f7f7;
                                                background-color: #6ba733;
                                            }
                                            &.product-state-expired {
                                                color: #ffffff;
                                                background-color: #ef233c;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                span {
                    &.no-data-found {
                        text-align: center;
                        display: block;
                        padding-top: 0px;
                        font-size: 24px;
                        font-weight: 500;
                        color: #666;
                        position: relative;
                        top: 50px;
                        &.hgth {
                            height: 120px;
                        }
                    }
                }
            }
        }
    }
}

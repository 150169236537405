.alert-wrapper {
    .alert-detail-container {
        .table-brb {
            margin-top: 15px;
            .table-responsive {
                overflow: hidden;
                table {
                    &.min-hght {
                        min-height: 200px;
                    }
                    thead {
                        background: #1776bf;
                        color: #fff;
                    }
                    tbody {
                        .spinner-table {
                            &.top-0 {
                                .spinner-wrapper {
                                    top: -85px;
                                    width: calc(100% - 200px);
                                }
                            }
                            .spinner-wrapper {
                                top: -10px;
                                width: calc(100% - 200px);
                            }
                        }
                        tr {
                            td {
                                padding: 15px;
                                &.active {
                                    color: #1776bf;
                                    cursor: pointer;
                                }
                                &.clr-grn {
                                    color: #92d050;
                                    font-weight: 500;
                                }
                                &.clr-rd {
                                    color: #ff6258;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
        span {
            &.no-data-found {
                text-align: center;
                display: block;
                padding-top: 0px;
                font-size: 24px;
                font-weight: 500;
                color: #666;
                position: relative;
                top: 50px;
            }
        }
    }
}

.alerts-container {
    .header {
        .watch-alerts {
            float: right;
            padding: 5px 10px;
            position: relative;
            top: -5px;
            left: 20px;
            i {
                font-size: 20px;
            }
        }
    }
    .content {
        font-size: 13px;
        color: #adb5bd;
    }
}

.misconfig-tags {
    padding: 6px 8px;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    margin-right: 2px;
}

.tier-mismatch {
    background-color: #ff9b85;
}

.pending-cleanup {
    background-color: #60d394;
}

.expiry-mismatch {
    background-color: #f7c162;
}

.infra-mismatch {
    background-color: #ee6055;
}

#countdown {
    color: white;
    height: 1.7rem;
    width: 5rem;
}
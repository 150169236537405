.line-legend {
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    li {
      margin-left: 10px;
      span {
        width: 20px;
        height: 3px;
        border-radius: 0px;
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
      }
    }
  }
}
.rounded-legend {
  ul {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    li {
      font-weight: 500;
      margin-right: 10px;
      span {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        margin-right: 10px;
        display: inline-block;
        font-size: 0.875rem;
      }
    }
  }
}
.average-price-card {
  .card {
    background: $card-accent-color;
  }

  h3 {
    margin-bottom: 0;
  }

  .icon-holder {
    border-radius: 5px;
    border: 1px solid $iconholder-primary-border-color;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $iconholder-primary-bg;
    color: $iconholder-primary-color;
    font-size: 20px;

    i {
      line-height: 1;
    }
  }
}

#dashboard-vmap {
  height: 161px;
  width: 100%;

  .jvectormap-zoomin,
  .jvectormap-zoomout {
    display: none;
  }
}
.map-height {
  height: 600px;
  .map {
    .jvectormap-container {
      svg {
        height: 500px;
      }
      .jvectormap-zoomin {
        position: absolute;
        top: 84px;
        z-index: 999;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        padding: 2px 6px;
        font-size: 22px;
        cursor: pointer;
        background: #fff;
        margin-left: 15px;
      }
      .jvectormap-zoomout {
        position: absolute;
        top: 130px;
        z-index: 999;
        z-index: 999;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        padding: 2px 6px;
        font-size: 22px;
        cursor: pointer;
        background-color: #fff;
        margin-left:15px;
      }
    }
  }
}
.proBanner {
  .purchase-popup {
    @extend .grid-margin;
    background: #fff;
    padding: 15px 20px;
    @include border-radius(3px);

    .btn {
      margin-right: 20px;
      font-weight: 500;
      color: $white;
      @include border-radius(5px);
      @include transition-duration(0.2s);

      &.download-button {
        background: rgba(249, 249, 249, 0.7);
        color: #969292;
        border: 1px solid darken(#e4e4e4, 5%);
      }

      &.purchase-button {
        background-color: theme-color(info);
        color: $white;
        border: none;
        line-height: 1;
        vertical-align: middle;
      }
    }

    p {
      margin-bottom: auto;
      margin-top: auto;
      color: darken(#e4e4e4, 40%);
      font-weight: 400;
      vertical-align: middle;
      line-height: 1;
    }

    i {
      vertical-align: middle;
      line-height: 1;
      margin: auto 0;
      color: darken(#e4e4e4, 20%);
    }
    .bannerClose {
      cursor: pointer;
    }
  }
  &.hide {
    display: none;
  }
}

.warning-card {
  border-radius: 20px;
  animation: shadowPulse 1.2s ease-in-out infinite;
}

@keyframes shadowPulse {
  50% {box-shadow: 0px 0px 30px rgba(179, 5, 5, 0.7);}
}

.wmio-pagination-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 50px;
    display: block;
  }
    // &.project-pagination {
    //   height: 120px !important;
    //   top: 5px;
    //   width: auto;
    // }
  
    // &.dashboard-pagination {
    //   margin-top: -38px;
    //   height: auto !important;
    //   top: 0;
    // }
  
    // &.receipes-pagination {
    //   height: 50px !important;
    //   top: -21px;
    // }
    // &.flow-debugger-panel {
    //   height: 75px !important;
    // }

    
    .pagination-container {
        position: relative;
        height: 50px;
        margin: 35px 0px 0;
        .wmio-pagination-left {
            position: absolute;
            top: 4px;
            line-height: 28px;
        
            .dark-color {
                color: #333;
                font-weight: 500;
            }
        }
    
        .wmio-pagination-middle {
            position: absolute;
            left: 45%;
            top: 0;
        
            ul {
                &.rc-pagination {
                    font-size: 12px;
                    font-family: "Arial";
                    user-select: none;
                    margin: auto;
                    margin-bottom: 20px;
                    padding: 0;
                }
        
                &.rc-pagination-simple {
                    li {
                        list-style: none;
        
                        &.rc-pagination-disabled {
                            cursor: default;
                        }
        
                        &.rc-pagination-prev {
                            pointer-events: none;
                            border: none;
                            height: 24px;
                            line-height: 24px;
                            margin: 0;
                            font-size: 18px;
                            color: #333;
                            float: left;
                            text-align: center;
                            border-radius: 2px;
                            min-width: 28px;
        
                            a,
                            button {
                                color: #333;
                                border: none;
                                background: none;
        
                                &.rc-pagination-item-link {
                                    &:after {
                                        content: "Page";
                                        font-size: 12px;
                                        color: #333;
                                        margin-top: 7px;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
        
                        &.rc-pagination-simple-pager {
                            font-weight: 500;
                            font-size: 14px;
                            float: left;
                            margin-right: 8px;
                            list-style: none;
        
                            input {
                                width: 60px;
                                text-align: center;
                                border-radius: 3px;
                                font-weight: 500;
                                color: #333;
        
                                &:hover {
                                    border: 1px solid #429a86;
                                }
                            }
        
                            .rc-pagination-slash {
                                margin: 0 7px 0 0;
                                display: inline-flex;
                                width: 12px;
                                overflow: hidden;
                                height: 25px;
        
                                &:before {
                                    content: "of";
                                    display: inline-block;
                                    position: relative;
                                    font-weight: 500;
                                    font-size: 14px;
                                    margin-top: 2px;
                                    margin-right: 2px;
                                }
                            }
                        }
        
                        &.rc-pagination-next {
                            display: none;
                        }
                    }
                }
            }
        }
    
        .wmio-pagination-right {
            &.pagination-panel {
                position: absolute;
                right: 0;
                top: 0;
        
                .rc-pagination {
                    font-size: 12px;
                    font-family: "Arial";
                    user-select: none;
                    margin: auto;
                    margin-bottom: 20px;
                    padding: 0;
        
                    li {
        
                        &.rc-pagination-jump-prev, &.rc-pagination-jump-next{
                            .rc-pagination-item-link{
                                border: none;
                                background: none;
                                display: none;
                            }
                            
                        }
        
                        list-style: none;
        
                        &.rc-pagination-disabled {
                            cursor: not-allowed;
                        }
        
                        &.rc-pagination-prev {
                            border: 1px solid #d9d9d9;
                            font-size: 18px;
                            color: #333;
                            min-width: 28px;
                            height: 28px;
                            line-height: 28px;
                            float: left;
                            text-align: center;
                            border-radius: 2px;
                            margin-right: 8px;
        
                            a,
                            button {
                                color: #333;
                                border: none;
                                background: none;
        
                                &:after {
                                    margin-top: -2px;
                                    position: relative;
                                    top: -2px;
                                }
                            }
                        }
        
                        &.rc-pagination-item {
                            cursor: pointer;
                            min-width: 28px;
                            height: 28px;
                            line-height: 28px;
                            text-align: center;
                            float: left;
                            border: 1px solid #d9d9d9;
                            margin-right: 8px;
                            border-radius: 2px;
                            outline-color: #429a86;
        
                            a,
                            button {
                                text-decoration: none;
                                color: #333;
                            }
        
                            &.rc-pagination-item-active {
                                background-color: #429a86;
                                border-color: #429a86;
        
                                a,
                                button {
                                    color: #fff;
                                }
                            }
        
                            &:not(.rc-pagination-item-active) {
                                &:hover {
                                    border-color: #429a86;
        
                                    a,
                                    button {
                                        color: #429a86;
                                    }
                                }
                            }
                        }
        
                        &.rc-pagination-next {
                            border: 1px solid #d9d9d9;
                            font-size: 18px;
        
                            a,
                            button {
                                color: #333;
                                border: none;
                                background: none;
        
                                &:after {
                                    margin-top: -2px;
                                    position: relative;
                                    top: -2px;
                                }
                            }
                        }
        
                        &.rc-pagination-jump-next {
                            &:hover {
                                a,
                                button {
                                    &.rc-pagination-item-link {
                                        color: #429a86;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        display: block;

        .pagination-left {
            position: absolute;
            line-height: 28px;
            top: 0;
            color: var(--wmio-primary-text-color);
            span {
                &.dark-color {
                    color: var(--wmio-primary-text-color);
                    font-weight: 500;
                }
            }
        }
        .pagination-middle {
            position: absolute;
            left: 45%;
            top: 0;
            color: var(--wmio-primary-text-color);
            .rc-pagination-next a:after, .rc-pagination-next button:after {
                margin-top: 2px;
            }
            ul {
                &.rc-pagination.rc-pagination-simple {
                    li {
                        &.rc-pagination-simple-pager {
                            font-weight: 500;
                            font-size: 14px;
                            input {
                                width: 36px;
                                text-align: center;
                                border-radius: 3px;
                                font-weight: 500;
                                color: var(--wmio-primary-text-color);
                            }
                            span {
                                &.rc-pagination-slash {
                                    margin: 0 7px 0 0;
                                    display: inline-flex;
                                    width: 14px;
                                    overflow: hidden;
                                    height: 17px;
                                }
                                &:before {
                                    content: "of";
                                    display: inline-block;
                                    position: relative;
                                    font-weight: 500;
                                    font-size: 14px;
                                    margin-right: 2px;
                                }
                            }
                        }
                        &.rc-pagination-next {
                            display: none;
                        }
                        &.rc-pagination-prev {
                            pointer-events: none;
                            a,button {
                                &.rc-pagination-item-link {
                                    &:after {
                                        content: "Page";
                                        font-size: 12px;
                                        color: var(--wmio-primary-text-color);
                                        margin-top: 2px;
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                        &.rc-pagination-disabled {
                            cursor: default;
                            &.rc-pagination-prev {
                                a,button {
                                    &.rc-pagination-item-link {
                                        &:after {
                                            content: "Page";
                                            font-size: 12px;
                                            color: var(--wmio-primary-text-color);
                                            margin-top: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .pagination-right {
            position: absolute;
            &.right-side-pagination {
                right: 61px;
            }
        }
        .rc-pagination {
            font-size: 12px;
            font-family: "Arial";
            user-select: none;
            margin: auto;
            // width: 50%;
            margin-bottom: 20px;
            padding: 0;
            > li {
                list-style: none;
            }
        }
        .rc-pagination-total-text {
            float: left;
            height: 30px;
            line-height: 30px;
            list-style: none;
            padding: 0;
            margin: 0 8px 0 0;
        }
        .rc-pagination:after {
            content: " ";
            display: block;
            height: 0;
            clear: both;
            overflow: hidden;
            visibility: hidden;
        }
        .rc-pagination-item {
            cursor: pointer;
            border-radius: 6px;
            min-width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            list-style: none;
            float: left;
            border: 1px solid #d9d9d9;
            background-color: #fff;
            margin-right: 8px;
            border-radius: 2px;
            a {
                text-decoration: none;
                color: var(--wmio-primary-text-color);
            }
            &:hover {
                border-color: #5faeec;
                a {
                    color: #5faeec;
                }
            }
        }
        .rc-pagination-item-active {
            background-color: #5faeec;
            border-color: #5faeec;
            a,
            &:hover a {
                color: #fff;
            }
        }
        .rc-pagination-jump-prev:after,
        .rc-pagination-jump-next:after {
            content: "•••";
            display: block;
            letter-spacing: 2px;
            color: var(--wmio-primary-text-color);
            font-size: 12px;
            margin-top: 1px;
        }
        .rc-pagination-jump-prev:hover:after,
        .rc-pagination-jump-next:hover:after {
            color: #5faeec;
        }
        .rc-pagination-jump-prev:hover:after {
            content: "«";
        }
        .rc-pagination-jump-next:hover:after {
            content: "»";
        }
        .rc-pagination-jump-prev-custom-icon,
        .rc-pagination-jump-next-custom-icon {
            position: relative;
        }
        .rc-pagination-jump-prev-custom-icon:after,
        .rc-pagination-jump-next-custom-icon:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            transition: all 0.2s;
            content: "•••";
            opacity: 1;
            display: block;
            letter-spacing: 2px;
            color: var(--wmio-primary-text-color);
            font-size: 12px;
            margin-top: 1px;
        }
        .rc-pagination-jump-prev-custom-icon .custom-icon-jump-prev,
        .rc-pagination-jump-next-custom-icon .custom-icon-jump-prev,
        .rc-pagination-jump-prev-custom-icon .custom-icon-jump-next,
        .rc-pagination-jump-next-custom-icon .custom-icon-jump-next {
            opacity: 0;
            transition: all 0.2s;
        }
        .rc-pagination-jump-prev-custom-icon:hover:after,
        .rc-pagination-jump-next-custom-icon:hover:after {
            opacity: 0;
            color: var(--wmio-primary-text-color);
        }
        .rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-prev,
        .rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-prev,
        .rc-pagination-jump-prev-custom-icon:hover .custom-icon-jump-next,
        .rc-pagination-jump-next-custom-icon:hover .custom-icon-jump-next {
            opacity: 1;
            color: #5faeec;
        }
        .rc-pagination-prev,
        .rc-pagination-jump-prev,
        .rc-pagination-jump-next {
            margin-right: 8px;
        }
        .rc-pagination-prev,
        .rc-pagination-next,
        .rc-pagination-jump-prev,
        .rc-pagination-jump-next {
            cursor: pointer;
            color: var(--wmio-primary-text-color);
            font-size: 10px;
            border-radius: 6px;
            list-style: none;
            min-width: 28px;
            height: 28px;
            line-height: 28px;
            float: left;
            text-align: center;
            border-radius: 2px;
        }
        .rc-pagination-prev a:after, .rc-pagination-prev button:after {
            content: "‹";
            display: block;
        }
        .rc-pagination-next a:after, .rc-pagination-next button:after {
            content: "›";
            display: block;
        }
        .rc-pagination-prev,
        .rc-pagination-next {
            border: 1px solid #d9d9d9;
            font-size: 18px;
        }
        .rc-pagination-prev a,
        .rc-pagination-next a {
            color: var(--wmio-primary-text-color);
        }
        .rc-pagination-prev a:after,
        .rc-pagination-next a:after {
            margin-top: -1px;
        }
        .rc-pagination-disabled {
            cursor: not-allowed;
            a {
                color: var(--wmio-primary-text-color);
            }
        }
        .rc-pagination-options {
            float: left;
            margin-left: 15px;
        }
        .rc-pagination-options-size-changer {
            float: left;
            width: 80px;
        }
        .rc-pagination-options-quick-jumper {
            float: left;
            margin-left: 16px;
            height: 28px;
            line-height: 28px;
            input {
                margin: 0 8px;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 6px;
                border: 1px solid #d9d9d9;
                outline: none;
                padding: 3px 12px;
                width: 50px;
                height: 28px;
                &:hover {
                    border-color: #5faeec;
                }
            }
            button {
                display: inline-block;
                margin: 0 8px;
                font-weight: 500;
                text-align: center;
                touch-action: manipulation;
                cursor: pointer;
                background-image: none;
                border: 1px solid transparent;
                white-space: nowrap;
                padding: 0 15px;
                font-size: 12px;
                border-radius: 6px;
                height: 28px;
                user-select: none;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                position: relative;
                color: rgba(0, 0, 0, 0.65);
                background-color: #fff;
                border-color: #d9d9d9;
                &:hover,
                &:active,
                &:focus {
                    color: #5faeec;
                    background-color: #fff;
                    border-color: #5faeec;
                }
            }
        }
        .rc-pagination-simple {
            .rc-pagination-prev,
            .rc-pagination-next {
                border: none;
                height: 24px;
                line-height: 24px;
                margin: 0;
                font-size: 18px;
            }
            .rc-pagination-simple-pager {
                float: left;
                margin-right: 8px;
                list-style: none;
                .rc-pagination-slash {
                    margin: 0 10px;
                }
                input {
                    margin: 0 8px;
                    box-sizing: border-box;
                    background-color: #fff;
                    border-radius: 6px;
                    border: 1px solid #d9d9d9;
                    outline: none;
                    padding: 5px 8px;
                    min-height: 20px;
                    &:hover {
                        border-color: #5faeec;
                    }
                }
                button {
                    display: inline-block;
                    margin: 0 8px;
                    font-weight: 500;
                    text-align: center;
                    touch-action: manipulation;
                    cursor: pointer;
                    background-image: none;
                    border: 1px solid transparent;
                    white-space: nowrap;
                    padding: 0 8px;
                    font-size: 12px;
                    border-radius: 6px;
                    height: 26px;
                    user-select: none;
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    position: relative;
                    color: rgba(0, 0, 0, 0.65);
                    background-color: #fff;
                    border-color: #d9d9d9;
                    &:hover,
                    &:active,
                    &:focus {
                        color: #5faeec;
                        background-color: #fff;
                        border-color: #5faeec;
                    }
                }
            }
        }
        @media only screen and (max-width: 1024px) {
            .rc-pagination-item-after-jump-prev,
            .rc-pagination-item-before-jump-next {
                display: none;
            }
        }
    }
  
  
.help-container {
    .help-wrapper {
        .help-overview-container {
            .card {
                border-radius: 20px;
                .card-body.default-setng {
                    padding: 1rem 1rem 0;
                    .admin-url-content {
                        ul {
                            display: block;
                            margin: 0px 25px;
                            min-height: 100px;
                            li {
                                &.item {
                                    font-size: 15px;
                                    .content {
                                        a {
                                            font-size: 15px;
                                            text-decoration: none;
                                            color: #333;
                                            &:hover {
                                                color: #429a86;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

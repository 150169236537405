.modal-content {
    width: 86.1rem;
    margin-left: -25.3rem;
    margin-top: 5.4rem;
}

.modal-body {
    .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        position: relative;
    }

    .steps .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        border: 0.3rem solid #D0D0D0;
        background: #D0D0D0;
        border-radius: 50%;
        font-weight: bold;
        z-index: 1;
        color: #D0D0D0;
        transition-delay: 1s;
        transition: all 2s ease;
    }

    .steps .circle.active {
        border-color: #283A46;
        color: #283A46;
    }

    .steps .progress {
        position: absolute;
        height: 0.3rem;
        width: 79rem;
        background: #D0D0D0;
    }

    .progress .indicator {
        position: absolute;
        height: 100%;
        background: #429a86;
        z-index: 0;
        transition: all 2s ease;
    }

    .labels {
        display: flex;
    }

    .labelsTitle {
        font-weight: 500;
    }
}
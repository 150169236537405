/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";

/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";
@import "../app/tenantsOverview/tenantsOverview.scss";
@import "../app/tenantsProvisioning/tenantProvisioning.scss";
@import "../app/shared/pagination/pagination.scss";
@import "../app/viewDetails/view-details.scss";
@import "../app/alerts/alert-details.scss";
@import "../app/help/help.scss";

.jvectormap-tip {
    position: absolute;
    display: none;
    border: solid 1px #cdcdcd;
    border-radius: 3px;
    background: #292929;
    color: white;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px;
    z-index: 99999;
}

.fusioncharts-container g:nth-child(1) {
    display: none;
}

.alerts-container {

    // min-height: 200px;
    // .header {
    //     font-size: 18px;
    //     font-weight: 500;
    // }
    .alerts-content {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }

    .alert-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 10rem;
        text-decoration: none;

        .expired-count {
            color: rgb(192, 0, 0);
            font-size: 3rem;
        }

        .alert-description {
            color: rgb(154, 154, 152);
            font-size: 1.2rem;
        }

        .to-expire-count {
            color: rgb(214, 77, 40);
            font-size: 3rem;
        }

        .misconfigured-tenant-count {
            color: rgb(214, 173, 40);
            font-size: 3rem;
        }
    }
}

// .overview-customer-container {
//     min-height: 200px;
//     .overview-customer-item {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: space-evenly;
//         min-height: 180px;
//         .overview-customer-description {
//             color: rgb(154, 154, 152);
//             font-size: 18px;
//             margin-top: -50px;
//         }
//         .total-count {
//             color: rgb(89, 63, 236);
//             font-size: 30px;
//         }
//     }
// }

.status-container {
    .header {
        font-size: 18px;
        font-weight: 500;
    }

    .products-container {
        display: flex;
        justify-content: center;
    }

    .product {
        margin: 10px;
        height: 30px;
        width: 80px;
        border-radius: 14px;
        border: 2px solid rgb(127, 127, 127);
        background-color: #fff;
        text-align: center;
        margin-right: 15px;

        &.active {
            background-color: rgb(217, 217, 217);
        }
    }

    .infra-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px 10px;
        margin: 10px;
    }

    .infra-item {
        height: 45px;
        width: 100px;
        border-radius: 5px;
        background-color: rgb(146, 208, 80);
        display: flex;
        justify-content: center;
        align-items: center;

        &.error {
            background-color: rgb(214, 77, 40);
        }
    }
}

.clr-wmio {
    background-color: #8862e0;
    width: 20px !important;
    height: 3px !important;
    position: relative;
    top: -1px;
}

.clr-b2b {
    background-color: #8862e0;
    width: 20px !important;
    height: 3px !important;
    position: relative;
    top: -1px;
}

.clr-cc {
    background-color: #8862e0;
    width: 20px !important;
    height: 3px !important;
    position: relative;
    top: -1px;
}

.clr-apigw {
    background-color: #8862e0;
    width: 20px !important;
    height: 3px !important;
    position: relative;
    top: -1px;
}

.line-clr {
    width: 20px !important;
    height: 3px !important;
    position: relative;
    top: -1px;

    &.clr-wmio {
        background-color: #8862e0;
    }

    &.clr-b2b {
        background-color: #5ed2a1;
    }

    &.clr-cc {
        background-color: #90c2e7;
    }

    &.clr-apigw {
        background-color: #4e8098;
    }
}

.new-trials-legend {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-bar {
    font-size: 15px;
    height: 35px !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    &::placeholder {
        font-size: 15px;
        color: #999;
    }
}

.r1 {
    height: 30rem;
    ;
}

.r2 {
    height: 500px;
}

.r3 {
    height: 450px;
}

.r4 {
    height: 500px;
}

.r0 {
    height: 40.5rem;
}

.r5 {
    height: 35.2rem;
}

.sidebar .sidebar-brand-wrapper .brand-logo-mini {
    position: relative;
    top: -7px;
}

html,
body {
    height: 100%;

    #root {
        height: inherit;

        .container-scroller {
            height: inherit;

            .container-fluid {
                height: 100%;

                .main-panel {
                    height: inherit;

                    .content-wrapper {
                        overflow: auto;
                    }
                }
            }
        }
    }
}

ul {
    &.tab-container {
        list-style: none;
        margin: 0;
        padding: 0;
        padding-top: 63px;

        &.tab-bottom {
            position: absolute;
            bottom: 0;
        }

        li {
            &.tab-list {
                width: 70px;
                height: 70px;
                display: block;
                background: #283a47;
                position: relative;
                border-bottom: 5px solid transparent;

                // border-right: 1px solid transparent;
                ul {
                    &.inner-sub-menu-list {
                        display: none;
                        width: 0px;
                        // height: 125px;
                        background: #fff;
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        position: absolute;
                        left: 0px;
                        top: 0;
                        background: #23313b;
                        color: #fff;
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                        transition: 0.6s;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

                        li {
                            &.nav-item {
                                border-bottom: 5px solid transparent;
                                font-size: 14px;
                                font-weight: 400;

                                &.active {
                                    border-bottom: 5px solid #429a86;
                                    background-color: #f3f3f3;

                                    &:last-child {
                                        border-bottom-right-radius: 5px;
                                    }

                                    a {
                                        &.nav-link {
                                            color: #429a86;

                                            font-weight: 500;
                                        }
                                    }
                                }

                                &:hover {
                                    // border-bottom: 5px solid #429a86;
                                    background-color: #f3f3f3;

                                    &:last-child {
                                        border-bottom-right-radius: 5px;
                                    }

                                    a {
                                        &.nav-link {
                                            color: #429a86;

                                            font-weight: 500;
                                        }
                                    }
                                }

                                a {
                                    &.nav-link {
                                        color: #f3f3f3;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    /* background: linear-gradient(to top, #5768f3, #1c45ef); */
                    /* left: 15px; */
                    // transition: 0.5s;
                    /* width: 100px; */
                    background: #f3f3f3;

                    // border-bottom: 5px solid #429a86;
                    a {
                        &.title {
                            color: #429a86;
                        }
                    }

                    ul {
                        &.inner-sub-menu-list {
                            display: block;
                            left: 70px;
                            width: 200px;
                            transition: 0.6s;
                        }
                    }
                }

                &.active {
                    background: #f3f3f3;
                    border-bottom: 5px solid #429a86;

                    // border-right: 1px solid #eee;
                    a {
                        &.title {
                            color: #429a86;
                        }
                    }
                }

                a {
                    &.title {
                        padding: 20px 0 0;
                        display: block;
                        line-height: 32px;
                        color: #fff;
                        text-decoration: none;
                        font-size: 25px;
                        display: block;
                        text-align: center;
                    }

                    img {
                        width: 25%;
                        margin: auto;
                        display: block;
                        padding-top: 25px;
                    }
                }
            }
        }
    }
}

ul {
    &.tab-content {
        position: fixed;
        right: 2%;
        top: 0px;
        z-index: 9999;
        height: 63px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: #cce4d6c2;
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
        list-style: none;
        padding-left: 19px;
        // width: 180px;
        padding-top: 0px;
        padding-right: 1rem;

        li {
            &.tab-li {
                float: left;
                width: 35px;
                height: 35px;
                background-color: #f6fff8;
                // border: 2px solid red;
                border-radius: 50%;
                // margin-top: 6px;
                margin-right: 12px;

                &.active,
                &:hover {
                    background-color: #eaf4f4;

                    a {
                        &.tab-href {
                            color: #59b19f;
                        }
                    }
                }

                a {
                    &.tab-href {
                        color: rgb(131, 131, 131);
                        font-size: 30px;
                        position: relative;
                        top: -12px;
                        text-align: center;
                        display: block;

                        i {
                            &.mdi.mdi-aws {
                                position: relative;
                                top: 5px;
                            }

                            &.mdi.mdi-azure {
                                position: relative;
                                top: 1px;
                            }

                            &.mdi.mdi-earth {
                                position: relative;
                                top: 3px;
                            }
                        }
                    }
                }
            }
        }

        .dropdown-header-inner-text-box {
            float: right;
            margin-right: 12px;

            // margin-top: 5px;
            button {
                border-bottom-right-radius: 20px !important;
                border-top-right-radius: 20px !important;
                border-bottom-left-radius: 20px !important;
                border-top-left-radius: 20px !important;
                padding: 8px;
                border-radius: 0;
                margin-left: -1px;
                box-shadow: none;
                opacity: 1;
                transition: none;
                text-transform: capitalize;
                background-color: #fff;
                color: #333;
                height: 35px;
                width: 105px;
                text-align: left;

                &::after {
                    float: right;
                }

                &.btn-outline-secondary.dropdown-toggle {
                    background-color: #fff;
                    color: #333;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .dropdown-menu {
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;

                top: -12px !important;
                min-width: 6rem;
                text-align: left;
                left: 7px !important;

                a {
                    font-size: 13px;
                    color: #333;
                    text-align: left;
                    padding-left: 15px;

                    &.dropdown-item.active {
                        color: #429a86;
                    }
                }
            }
        }
    }
}

.toggle-content {
    position: relative;
    top: -1.5rem;
    float: right;
    height: 0rem;

    span {
        float: left;
        position: relative;
        top: -2px;

        &.free-title-active {
            left: -9px;
            color: #000000;
        }

        &.paid-title-active {
            left: 9px;
            color: #000000;
        }

        &.free-title-inactive {
            left: -9px;
            color: #a0a0a0;
        }

        &.paid-title-inactive {
            left: 9px;
            color: #a0a0a0;
        }
    }

    input {
        position: relative;
        float: left;
    }

    input[type="checkbox"] {
        height: 0;
        width: 0;
        visibility: hidden;
    }

    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 40px;
        height: 18px;
        background: #6875c0;
        display: block;
        border-radius: 100px;
        position: relative;
        margin-bottom: 0;
        float: left;
    }

    label:after {
        content: "";
        position: absolute;
        top: 2.5px;
        left: 5px;
        width: 12px;
        height: 12px;
        background: #fff;
        border-radius: 90px;
        // transition: 0.3s;
    }

    input:checked+label {
        background: #59b19f;
    }

    input:checked+label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }

    label:active:after {
        width: 12px;
    }
}

#overview-custom {
    margin-top: 20px;
}

.search-wrapper {
    position: absolute;
    right: 35px;
    top: 13px;

    input {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

span {
    &.no-data-found {
        text-align: center;
        display: block;
        padding-top: 0px;
        font-size: 24px;
        font-weight: 500;
        color: #666;
        position: relative;
        top: 50px;
    }
}

a {
    &.btn.btn-inverse-secondary.export-btn {
        float: right;
        // border-radius: 20px;
        // width: 40px;
        // height: 40px;
        margin-top: -44px;
        color: #666;
        display: flex;
        align-items: center;

        i {
            font-size: 20px;
            margin-left: -6px;
        }

        &.prvisn-faliure-bnt {
            margin-top: auto;
        }

        &:hover {
            color: #ffffff;
            background-color: #252C46;
            border-color: #252C46;
        }
    }
}

.enhance-card:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.5s ease-in-out;
}

.enhance-card {
    border-radius: 1.2rem;
    ;
}

// .bar {
//     width: 50rem !important;
// }

#map>div:has(> span) {
    height: 40rem;
}

.map-box-view {
    position: absolute;
    left: 5px;
    bottom: -28.7rem;
    min-height: 150px;
    padding: 30px;
    // margin-top: -190px;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background: transparent;

    span {
        &.legend-title {
            font-weight: 500;
            font-size: 20px;
            display: block;
            margin-bottom: 10px;
        }

        &.map-1 {
            border-left: 5px solid red;
            padding: 2px 10px 0;
            line-height: 23px;
            font-size: 17px;
            display: block;
            margin-bottom: 7px;

            &.map-color-1 {
                border-left: 5px solid #ffd74d;
            }

            &.map-color-0 {
                border-left: 5px solid #cfd558;
            }

            &.map-color-2 {
                border-left: 5px solid #fb8c00;
            }

            &.map-color-3 {
                border-left: 5px solid #e65100;
            }
        }

        .provider-count {
            color: #8d8d8d;
            font-size: 14px;
        }
    }
}

.mini-alert {
    position: absolute;
    right: 5px;
    top: 3px;
    min-height: 150px;
    padding: 30px;
    // margin-top: -190px;
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background: transparent;

    span {
        &.alerts {
            font-size: 30px;
            border-radius: 100%;
            float: left;
            width: 55px;
            height: 55px;
            display: block;
            margin-right: 20px;
            margin-top: 5px;
            text-align: center;
            cursor: pointer;
        }

        .mdi {
            font-size: 35px;
            color: #e63946;

            .badge {
                height: 17px;
                width: 17px;
                border-radius: 10px;
                font-size: 12px;
                position: absolute;
                right: 3px;
                bottom: 0px;
                color: rgb(107, 107, 107);
                display: inline-flex;
                align-items: center;
            }
        }

        &.legend-title {
            font-weight: 500;
            font-size: 20px;
            display: block;
            margin-bottom: 10px;
        }

        &.map-1 {
            border-left: 5px solid red;
            padding: 2px 10px 0;
            line-height: 23px;
            font-size: 17px;
            display: block;
            margin-bottom: 7px;

            &.map-color-1 {
                border-left: 5px solid #ffd74d;
            }

            &.map-color-0 {
                border-left: 5px solid #cfd558;
            }

            &.map-color-2 {
                border-left: 5px solid #fb8c00;
            }

            &.map-color-3 {
                border-left: 5px solid #e65100;
            }
        }

        .provider-count {
            color: #8d8d8d;
            font-size: 14px;
        }
    }
}

.sider-widgets-container {
    .overview-widget-back {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background: #333;
        opacity: 0.3;
        left: 0;
        right: 0;
        z-index: 9999;
        bottom: 0;
        display: block;
    }

    .sider-widgets {
        position: fixed;
        width: 450px;
        display: block;
        height: 100%;
        z-index: 9999999;
        right: 0;
        background: #ffff;
        top: 0;
        bottom: 0;
        transform: translate3d(460px, 0, 0);
        transition: transform 0.25s linear;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
        // border-left-style: dashed;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding: 20px;
        z-index: 999999999;

        // display: none;
        // &:hover {
        //     box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.1) !important;
        //     transition: box-shadow 0.5s ease-in-out !important;
        // }
        &.open {
            // display: block;
            transform: translate3d(0, 0, 0);
        }

        .sider-widget-header {
            border-bottom: 1px solid #ddd;
            display: block;
            width: 100%;
            margin-bottom: 25px;
            padding-bottom: 35px;
            padding-left: 16px;

            .mdi.mdi-close {
                float: right;
                font-size: 24px;
                position: relative;
                top: -6px;
                cursor: pointer;
            }

            .widget-title {
                float: left;
                font-size: 18px;
                font-weight: 500;
                text-transform: capitalize;
            }
        }

        .row {
            margin: 0;
            clear: both;

            .col {
                padding: 0;
            }
        }

        .alert-activities-container {
            padding-top: 10px;

            .grid-margin.stretch-card {
                margin-bottom: 20px;

                .card {
                    box-shadow: none;
                    top: -10px;

                    .card-activity {
                        h4 {
                            font-size: 20px;
                        }

                        ul {
                            &.timeline {
                                max-height: 265px;
                                overflow: auto;
                                margin: auto;
                                padding-left: 5px;
                                padding-top: 5px;
                            }
                        }

                        .d-block.mt-3 {
                            margin-top: 0.5rem !important;
                            float: right;
                        }
                    }
                }
            }
        }

        .sci-appswitcher-inner {
            .grid-margin.stretch-card {
                margin-bottom: 20px;

                .card {
                    top: -20px;
                    box-shadow: none;

                    .card-title {
                        font-weight: 500;
                        color: #343a40;
                        font-family: "Roboto", sans-serif;
                        font-size: 1.2rem;
                        position: relative;
                    }

                    .card-description {
                        font-weight: 400;
                        color: #343a40;
                        font-family: "Roboto", sans-serif;
                        font-size: 20px;
                    }

                    // overflow-y: auto;
                    // max-height: calc(100vh - 280px);
                    position: relative;

                    // top: 25%;
                    .row {
                        margin: 0;

                        .col-md-4.grid-margin {
                            padding: 10px 15px 5px;
                            text-align: center;
                            margin-bottom: 0;

                            a {
                                display: block;
                                text-decoration: none;

                                img {
                                    width: 70px;
                                    height: 70px;
                                    padding: 10px;
                                    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
                                    border-radius: 25%;
                                }

                                .active-product:hover {
                                    animation: fadeInShadow 3s infinite
                                }

                                .inactive-product {
                                    opacity: 0.5;
                                }

                                @keyframes fadeInShadow {
                                    50% {
                                        box-shadow: 0px 0px 30px rgba(99, 99, 99, 0.7);
                                    }
                                }

                                span {
                                    margin-top: 10px;
                                    display: block;
                                    color: rgb(51, 51, 51);
                                    font-size: 12px;
                                    // &:hover {
                                    //     text-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }

        .ttl-customer-footer {
            position: absolute;
            bottom: 0;
            height: 50px;
            left: 20px;
            right: 20px;
            border-top: 1px solid #ddd;
            display: flex;
            justify-content: left;
            align-items: center;
            padding-left: 16px;

            span {
                &.footer-tittle {
                    // padding: 15px 0 0;
                    padding-left: 0;
                    display: block;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}

@property --p {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

.pie {
    --p: 20;
    --b: 10px;
    --c: transparent;
    --w: 100px;
    z-index: 1;
    width: var(--w);
    aspect-ratio: 1;
    position: relative;
    display: inline-grid;
    margin: 5px;
    place-content: center;
    font-size: 20px;
    font-weight: bold;
    font-family: sans-serif;
}

.pie:before,
.pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
}

.pie:before {
    inset: 0;
    background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b) var(--b) no-repeat,
        conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
    z-index: -1;
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side, #0000 calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
    inset: calc(50% - var(--b) / 2);
    background: var(--c);
    transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
}

.animate {
    animation: p 1s 0.5s both;
}

.no-round:before {
    background-size: 0 0, auto;
}

.no-round:after {
    content: none;
}

@keyframes p {
    from {
        --p: 0;
    }
}

.pie-container {
    height: 109px;
    display: block;
}

.brb-pie {
    width: 6.2rem;
    height: 6.2rem;
    border: 10px solid #ddd;
    border-radius: 100%;
    position: absolute;
    top: 20px;
    z-index: -1;
    margin-left: 0.3rem;

    &.brb-pie-1 {
        top: 0.3rem;
    }
}

.mdi-information-outline {
    color: #474747;
}

.pie-title {
    font-size: 14px;
    font-weight: 500;
    color: #292929;
    font-family: "roboto";
    display: block;
    text-align: center;
    position: relative;
    top: 6px;
    text-align: center;
    display: block;
    // vertical-align: middle;
    left: 8px;

    &.pie-title-1 {
        left: auto;
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.dot {
    background-color: rgb(110, 110, 110) !important;
}

.carousel-root {
    margin-bottom: -15px;
    height: 200px;
}

.control-dots {
    bottom: -10px;
}

.sidebar-alert-container {
    max-height: 155px;
}

.curs-pntr {
    cursor: pointer;
}

.tooltip-content {
    span {
        display: block;
        padding: 5px;
    }
}

.card-counter {
    box-shadow: 0.125rem 0.125rem 0.625rem #DADADA;
    margin: 0.3125rem;
    padding: 1.25rem 0.625rem;
    background-color: #fff;
    height: 6.25rem;
    border-radius: 0.3125rem;
    transition: 0.3s linear all;
    margin-bottom: 2.1rem;
}

.card-counter:hover {
    box-shadow: 0.25rem 0.25rem 1.25rem #DADADA;
    transition: 0.3s linear all;
}

.card-counter.primary {
    background-color: #007bff;
    color: #FFF;
}

.card-counter.danger {
    background-color: #ef5350;
    color: #FFF;
}

.card-counter.success {
    background-color: #66bb6a;
    color: #FFF;
}

.card-counter.info {
    background-color: #26c6da;
    color: #FFF;
}

.card-counter.pink {
    background-color: #e85a9d;
    color: #FFF;
}

.card-counter i {
    font-size: 3.125rem;
    opacity: 0.2;
}

.card-counter .count-numbers {
    position: absolute;
    right: 2.1875rem;
    margin-top: -3.5rem;
    font-size: 2rem;
    display: block;
    font-weight: 400;
}

.card-counter .count-name {
    position: absolute;
    right: 2.1875rem;
    font-style: italic;
    text-transform: capitalize;
    opacity: 0.8;
    display: block;
    margin-top: -0.5rem;
    font-size: 1.125rem;
    font-weight: 300;
}

.jsonToExcel {
    background: #429a86;
    float: right;
    padding: 0.3rem 1rem;
    margin-top: -5rem;
    border: none;
    color: white;
    font-weight: 500;
}

#logout {
    font-size: 1.6rem;
    color: darkolivegreen;
    background: white;
    border: white;
    padding-left: 0.5rem;
    height: 2.3rem;
    width: 2.3rem;
    border-radius: 2rem !important;
}
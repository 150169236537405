/* Cards */

.card {
  border: none;
  box-shadow: $card-shadow-color;
  &.paid-card {
    height: 465px;
    .d-flex.align-items-end {
      margin-bottom: 45px;
    }
  }
  &.pie-bar, &.metering-trend {
    .card-body {
      .wrapper {
        .dropdown-container {
          .dropdown-header-inner-text-box {
            float: right;
            position: relative;
            top: -28px;
            button {
              border-bottom-right-radius: 0px !important;
              border-top-right-radius: 0px !important;
              padding: 8px;
              border-radius: 0;
              margin-left: -1px;
              box-shadow: none;
              opacity: 1;
              transition: none;
              text-transform: capitalize;
              // width: 100px;
              min-width: 100px;
              max-width: 150px;
              text-align: left;
              &::after {
                float: right;
              }
            }
            .dropdown-menu {
              border-bottom-right-radius: 0px;
              border-bottom-left-radius: 0px;
              top: -12px !important;
              min-width: 6rem;
              text-align: left;
              a {
                font-size: 13px;
                color: #333;
                &.dropdown-item.active {
                  color: #007bff;
                }
              }
            }
          }
        }
        .inner-wrapper {
          position: absolute;
          margin-left: 7%;
          margin-top: 105px;
          .card-title {
            font-size: 24px;
          }
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  .card-body {
    padding: $card-padding-y $card-padding-x;
    &.default-setng {
      .card-title {
        font-size: 22px;
        margin-bottom: 15px !important;
      }
    }
    #map-hover {
      .pop-up {
        position: absolute;
        /* border: solid 1px #cdcdcd; */
        border-radius: 0px;
        background: #fff;
        color: #333;
        font-family: sans-serif, Verdana;
        font-size: smaller;
        padding: 3px;
        z-index: 99;
        width: 25rem;
        height: 29rem;
        visibility: hidden;
        box-shadow: 0 0 5px black;
        border-radius: 10px;
        opacity: 0.9;
        transition: 0.6s;
        span {
          &.title {
            font-size: 20px;
            padding: 10px;
            display: block;
            border-bottom: 1px solid #ddd;
            color: #333;
          }
        }
        .sci-appswitcher-inner {
          // overflow-y: auto;
          max-height: calc(100vh - 280px);
          .row {
            margin: 0;
            .col-md-4.grid-margin {
              padding: 15px;
              text-align: center;

              a {
                display: block;
                img {
                  width: 65px;
                  height: 65px;
                  padding: 5px;
                  border: 2px solid #1776bf;
                  border-radius: 50px;
                }
                span {
                  margin-top: 10px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .card-title {
      font-size: 1.2rem;
    }
    + .card-body {
      padding-top: 1rem;
    }
    .card-icon-indicator {
      width: 45px;
      height: 45px;
      font-size: 20px;
      color: $white;
      border-radius: 50px;
      text-align: center;
      line-height: 2.2;
    }
  }
  .card-title {
    font-weight: 500;
    color: $card-title-color;
    margin-bottom: 15px;
    // text-transform: capitalize;
    font-family: $type1;
    font-size: 14px;
  }
  .card-subtitle {
    @extend .text-gray;
    font-family: $type1;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .card-description {
    color: $card-description-color;
    margin-bottom: 1.5rem;
    font-family: $type1;
    font-weight: 400;
    color: $card-description-color;
  }
  &.card-outline-success {
    border: 1px solid theme-color("success");
  }
  &.card-outline-primary {
    border: 1px solid theme-color("primary");
  }
  &.card-outline-warning {
    border: 1px solid theme-color("warning");
  }
  &.card-outline-danger {
    border: 1px solid theme-color("danger");
  }
  &.card-rounded {
    @include border-radius(5px);
  }

  &.card-faded {
    background: #b5b0b2;
    border-color: #b5b0b2;
  }
  &.card-circle-progress {
    color: $white;
    text-align: center;
  }
  &.card-img-holder {
    position: relative;
    .card-img-absolute {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }
  }
}

@each $color, $value in $theme-colors {
  .card-inverse-#{$color} {
    @include card-inverse-variant(
      rgba(theme-color($color), 0.2),
      theme-color-level($color, 1),
      theme-color-level($color, 3)
    );
  }
}
